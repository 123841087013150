import { useEffect, useState, RefObject } from 'react';

export interface IntersectionObserverProps {
    ref: RefObject<HTMLDivElement> | null;
    options: IntersectionObserverInit;
}

export const useIntersectionObserver = ({
    ref,
    options,
}: IntersectionObserverProps) => {
    const [isIntersecting, setIsIntersecting] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver(([entry]) => {
            setIsIntersecting(entry.isIntersecting);
        }, options);

        if (ref?.current) {
            observer.observe(ref.current);
        }

        return () => {
            if (ref?.current) {
                observer.unobserve(ref.current);
            }
        };
    }, []);

    return isIntersecting;
};
