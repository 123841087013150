'use client';

import classNames from 'classnames';
import Image from 'next/image';
import { getFullName } from '@get-mosh/msh-shared';

type DoctorCardProps = {
    image: string;
    firstName: string;
    lastName: string;
    titleText: string | null;
    specialisation: string | null;
    bio?: string | null;
    className?: string;
    onOpenBio?: () => void;
};

const DoctorGrid = ({
    className,
    doctors,
}: {
    className?: string;
    doctors: DoctorCardProps[];
}) => {
    return (
        <div
            className={classNames(
                'flex w-full flex-row flex-nowrap items-center gap-4 overflow-x-auto px-2 py-4 pb-2 text-center',
                'md:flex-wrap md:justify-center',
                className,
            )}
        >
            {doctors.map((doctor, i) => (
                <DoctorGridCard key={i} {...doctor} />
            ))}
        </div>
    );
};

export const DoctorGridCard = ({
    image,
    firstName,
    lastName,
    titleText,
    specialisation,
    className,
}: DoctorCardProps) => {
    const name = getFullName({ firstName, lastName, titleText });

    return (
        <div
            className={classNames(
                'flex flex-col justify-start gap-4 rounded-b-2xl rounded-t-full border border-grey-200 bg-white p-4 shadow-md',
                'h-72 w-52',
                className,
            )}
        >
            <picture className="relative min-h-44 w-44 object-cover">
                <Image
                    className="rounded-full border border-grey-200 object-cover"
                    src={image}
                    fill
                    alt={name}
                />
            </picture>
            <div className="flex flex-col gap-1 text-center">
                <h4 className="font-demi text-regular">{name}</h4>
                {specialisation ? (
                    <p className="w-full text-grey-400">{specialisation}</p>
                ) : null}
            </div>
        </div>
    );
};

export default DoctorGrid;
