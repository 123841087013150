import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import HTMLReactParser from 'html-react-parser';

export type ListProps = {
    items: ListItemType[];
    className?: string;
    type?: 'ul' | 'ol' | undefined;
    html?: boolean | false;
    icon?: ListItemIconType;
    textStyles?: string;
};

export type ListItemIconType = {
    background?: string;
    border?: string | boolean;
    colour?: string;
    faIcon: IconDefinition;
    size?: string;
};
export type ListItemType = {
    text: string;
    className?: string;
};

const List = ({
    icon = {
        faIcon: faCheck,
    },
    ...props
}: ListProps) => {
    return (
        <div className={'mosh-list'}>
            {props.type === 'ol' ? (
                <OrderedList
                    items={props.items}
                    html={props.html}
                    icon={icon}
                    className={props.className}
                    textStyles={props.textStyles}
                />
            ) : (
                <UnorderedList
                    items={props.items}
                    html={props.html}
                    icon={icon}
                    className={props.className}
                    textStyles={props.textStyles}
                />
            )}
        </div>
    );
};

const OrderedList = ({ ...list }: ListProps) => {
    return (
        <ol className={list.className}>
            <RenderListItems
                items={list.items}
                html={list.html}
                icon={list.icon}
                textStyles={list.textStyles}
            />
        </ol>
    );
};

const UnorderedList = ({ ...list }: ListProps) => {
    return (
        <ul className={list.className}>
            <RenderListItems
                items={list.items}
                html={list.html}
                icon={list.icon}
                textStyles={list.textStyles}
            />
        </ul>
    );
};

type ListItemProps = {
    html?: boolean;
    item: ListItemType;
    icon?: ListItemIconType;
    textStyles?: string;
};

const ListItem = ({ ...props }: ListItemProps) => {
    return (
        <li
            className={classNames(
                props.icon &&
                    'mb-2 flex flex-row items-start space-x-3 text-sm lg:text-regular',
                props.item.className,
            )}
        >
            {props.icon && (
                <FontAwesomeIcon
                    icon={props.icon.faIcon}
                    style={{ height: '24px' }}
                    className={classNames(
                        props.icon.background,
                        '-mt-1 flex h-6 w-6 rounded-full p-1 md:pt-1.5',
                        props.icon.border === undefined
                            ? 'border border-green-dark'
                            : props.icon.border,
                        props.icon.colour || 'text-green-dark',
                        props.icon.size,
                    )}
                />
            )}
            <span className={props.textStyles}>
                {props.html
                    ? HTMLReactParser(props.item.text)
                    : props.item.text}
            </span>
        </li>
    );
};

const RenderListItems = ({ ...list }: ListProps) => {
    return (
        <>
            {list.items.map((e, i) => (
                <ListItem
                    key={i}
                    item={e}
                    html={list.html}
                    icon={list.icon}
                    textStyles={list.textStyles}
                />
            ))}
        </>
    );
};

export default List;
