'use client';

import { useMemo, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Swiper as SwiperType } from 'swiper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/pro-solid-svg-icons';
import { Divider } from '@get-mosh/msh-shared';

type Review = {
    title: string;
    text: string;
    name: string;
};

export interface ReviewsSliderProps {
    className?: string;
    reviews: Array<Review>;
}

const ReviewsSlider = ({ reviews }: ReviewsSliderProps) => {
    const swiperRef = useRef<SwiperType>();
    const initialSlide = useMemo(
        () => Math.floor((reviews.length - 1) / 2),
        [reviews.length],
    );

    return (
        <Swiper
            onBeforeInit={(swiper) => {
                swiperRef.current = swiper;
            }}
            initialSlide={initialSlide}
            modules={[Autoplay]}
            autoplay={{
                pauseOnMouseEnter: true,
            }}
            loop
            centeredSlides
            slidesPerView={1.5}
            spaceBetween={16}
            breakpoints={{
                768: {
                    slidesPerView: 2,
                    spaceBetween: 24,
                },
                992: {
                    slidesPerView: 3.5,
                    spaceBetween: 24,
                },
                1200: {
                    slidesPerView: 4.5,
                    spaceBetween: 24,
                },
            }}
        >
            {reviews.map((review) => (
                <SwiperSlide
                    key={review.title}
                    className="flex flex-col justify-between rounded-2xl border border-grey-200"
                >
                    <div className="flex h-80 flex-col gap-3 p-4">
                        <h4 className="text-blue">{review.title}</h4>
                        <div className="flex flex-row gap-2">
                            {Array(5)
                                .fill(0)
                                .map((_, i) => (
                                    <FontAwesomeIcon
                                        key={i}
                                        icon={faStar}
                                        className="text-purple"
                                    />
                                ))}
                        </div>
                        <p>{review.text}</p>
                    </div>
                    <Divider />
                    <h5 className="px-4 pb-4 text-blue">{review.name}</h5>
                </SwiperSlide>
            ))}
        </Swiper>
    );
};

export default ReviewsSlider;
