'use client';

import { Button } from '@get-mosh/msh-shared';
import Link from 'next/link';
import { EventProps } from 'src/analytics/EventProps';
import { trackLandingPageButtonClick } from 'src/analytics/Events';

export type ProgramDetailsButtonProps = {
    text: string;
    link: string;
    eventProps?: EventProps;
};

function ProgramDetailsButton({
    text,
    link,
    eventProps,
}: ProgramDetailsButtonProps) {
    const onClick = () => {
        trackLandingPageButtonClick({
            location: 'Program details',
            label: text,
            ...eventProps,
        });
    };
    return <Button as={Link} href={link} text={text} onClick={onClick} />;
}

export default ProgramDetailsButton;
